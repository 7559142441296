export const makePartnerLogoUrl = (path) => {
  return (
    process.env.STATIC_SAFEWILL_COM__URL +
    '/images/partnerships/logos/' +
    path.split('/').pop()
  );
};

export default (_ctx, inject) => {
  inject('makePartnerLogoUrl', makePartnerLogoUrl);
  inject('staticUrl', process.env.STATIC_SAFEWILL_COM__URL);
};
