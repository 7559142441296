import Vue from 'vue';
import { SensitiveModel } from '@/plugins/sensitive/SensitiveModel';
import { mapping } from '@/plugins/sensitive/models/mapping';

export default (ctx) => {
  const $apollo = ctx.app.apolloProvider.defaultClient;
  const $vault = Vue.prototype.$privacyVaultService;
  SensitiveModel.initialise($apollo, $vault);

  const sensitivePlugin = {
    install() {
      Vue.prototype.$sensitive = mapping;
    },
  };
  Vue.use(sensitivePlugin);
  return mapping;
};
