export default {
  setShowCompletionPage({ commit }, value) {
    commit('setShowCompletionPage', value);
  },
  setProgress({ commit, rootState, rootGetters }, completedStepsData) {
    const mappedData = [];
    let completedSteps = 0;

    rootState.modules.modules.forEach((module) => {
      completedSteps += completedStepsData[module.name];

      mappedData.push({
        name: module.name,
        completedSteps: completedStepsData[module.name],
        totalSteps: module.totalSteps,
        completed: completedStepsData[module.name] >= module.totalSteps,
        progress:
          completedStepsData[module.name] >= module.totalSteps
            ? 1
            : completedStepsData[module.name] / module.totalSteps,
      });
    });

    commit('setModules', mappedData);
    commit(
      'setProgressPercentage',
      Math.min(100, (completedSteps * 100) / rootGetters['modules/totalSteps'])
    );
  },
};
