export default () => ({
  user: {
    id: null,
    firstName: null,
    middleName: null,
    lastName: null,
    dateOfBirth: null,
    residentialAddress: {
      country: null,
      region: null,
    },
  },
});
