export default {
  setShowCompletionPage: (state, value) => {
    state.showCompletionPage = value;
  },
  setModules: (state, data) => {
    state.modules = data;
  },
  setProgressPercentage: (state, data) => {
    state.progressPercentage = data;
  },
};
