const securityLink = {
  au: 'https://safewill.com/security',
  nz: 'https://safewill.com/nz/security',
};

const privacyLink = {
  au: 'https://safewill.com/privacy',
  nz: 'https://safewill.com/nz/privacy',
};

export { securityLink, privacyLink };
