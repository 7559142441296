import cookies from 'js-cookie';
import {
  getBaseUrl,
  getUserFullName,
  metaArrayToObject,
  isAdminOrHigher,
  hexToRgb,
  saveAnonymousProfile,
} from '@/utilities';
import { removeLegacyCookie } from '@/modules/cookies';
import {
  sanitizeLogInRedirect,
  sanitizeSignUpRedirect,
} from '@/utilities/allowed-redirects';

const GET_PARTNERSHIP_BRANDING = require('@/graphql/queries/GetPartnershipBranding');

const LOGIN_USER = require('~/graphql/mutations/LoginUser');
const GET_WILL_QUERY = require('~/graphql/queries/GetWill');
const GET_PRODUCTS_PURCHASED_INIT_QUERY = require('~/graphql/queries/GetProductsPurchasedInit');

const GET_PARTNERSHIP_APP_DATA = require('@/graphql/queries/GetPartnershipAppData');
const GET_PARTNERSHIP_AFFILIATE_FEATURES = require('@/graphql/queries/GetPartnershipAffiliateFeatures');

const CONSUMER_DASHBOARD_PATH = '/';
const ADMIN_DASHBOARD_PATH = '/admin';

export default async function ({
  app,
  $auth,
  store: { state, commit, dispatch },
}) {
  const client = app.apolloProvider.defaultClient;
  const currentPath = window.location.pathname;
  const currentPathElements = currentPath.split('/');
  let affiliateFromUrl = currentPathElements[1];
  if (app.i18n.localeCodes.includes(affiliateFromUrl)) {
    affiliateFromUrl = currentPathElements[2];
  }
  const cookie = cookies.get('affiliate_slug');

  let affiliateSlug = cookie || affiliateFromUrl;

  if (affiliateSlug) {
    const { data: brandingData } = await client.query({
      query: GET_PARTNERSHIP_BRANDING,
      variables: {
        slug: affiliateSlug,
      },
    });

    const root = document.documentElement;
    if (brandingData.getPartnershipBranding) {
      dispatch('affiliate/setSlug', affiliateSlug);
      cookies.set('affiliate_slug', affiliateSlug);
      saveAnonymousProfile({
        affiliateSlug,
      });

      const logo = brandingData.getPartnershipBranding.logo;
      const { theme, darker, lighter, landingImage } =
        brandingData.getPartnershipBranding.branding;

      dispatch('affiliate/setBranding', {
        logo,
        theme,
        darker,
        lighter,
        landingImage,
      });

      root.classList.add('--affiliate');
      if (theme) {
        root.style.setProperty('--theme', hexToRgb(theme));
      }
      if (darker) {
        root.style.setProperty('--darker', hexToRgb(darker));
      }
      if (lighter) {
        root.style.setProperty('--lighter', hexToRgb(lighter));
      }
    } else {
      root.classList.remove('--affiliate');
      affiliateSlug = 'sw';
    }
  }

  if ($auth.loggedIn) {
    const token = $auth.strategy.token.get() || '';

    await app.$apolloHelpers.onLogin(token.split(' ')[1]);

    try {
      const { data } = await client.mutate({ mutation: LOGIN_USER });
      const user = data.loginUser.user;

      if (user.person_id) {
        dispatch('directory-person/getUserDirectoryPerson', {
          id: user.person_id,
        });
      }

      if (user) {
        window.analytics?.track(data.loginUser.action, {
          userId: user.id,
          identity_provider: data.loginUser.identityProvider,
        });
        commit('setEmail', user.email);
        commit('setRole', user.role);
        commit('setUserId', user.id);
        commit('setVerified', user.verified);
        commit('setMasquerading', false);
        commit('setToken', token);
        commit('setAffiliateUserId', user.affiliate_user_id);
      } else {
        removeLegacyCookie();
        app.$apolloHelpers.onLogout();
        dispatch('resetUserState');
        dispatch('resetCheckoutState');
        dispatch('resetUI');
        await $auth.logout();
      }
    } catch (e) {
      console.error(e.message);
      removeLegacyCookie();
      app.$apolloHelpers.onLogout();
      dispatch('resetUserState');
      dispatch('resetCheckoutState');
      dispatch('resetUI');
      await $auth.logout();
    }

    try {
      let redirectPath = CONSUMER_DASHBOARD_PATH;
      let dashboardPath = CONSUMER_DASHBOARD_PATH;

      if (isAdminOrHigher(state.role)) {
        redirectPath = ADMIN_DASHBOARD_PATH;
        dashboardPath = ADMIN_DASHBOARD_PATH;
      } else {
        const { data } = await client.query({ query: GET_WILL_QUERY });
        if (data.getWill.will) {
          dispatch('setWillId', data.getWill.will.id);
          dispatch('setWillStatus', data.getWill.will.status);
          const zendeskUser = {
            name: getUserFullName(
              metaArrayToObject(data.getWill.will.meta || [])
            ),
            email: state.email,
          };
          if (window.zE) {
            window.zE('webWidget', 'identify', zendeskUser);
          }
        }

        const {
          data: { poa, getAtNeedCremationRequestsByInformantUserId },
        } = await client.query({
          query: GET_PRODUCTS_PURCHASED_INIT_QUERY,
          variables: {
            userId: data.getWill.will.userId,
          },
        });
        if (poa) {
          dispatch('poa/setPoaId', poa.id);
        }

        dispatch('coupon/getCouponLatestReferral');

        const fromLoginPage = cookies.get('from_login');
        if (
          fromLoginPage &&
          getAtNeedCremationRequestsByInformantUserId &&
          getAtNeedCremationRequestsByInformantUserId[0]?.status === 'CURRENT'
        ) {
          cookies.set('redirect', '/cremations/at-need');
        }
        cookies.remove('from_login');

        const signUpRedirectCookie = cookies.get('signup_redirect');
        const redirectCookie = cookies.get('redirect');

        redirectPath = redirectCookie
          ? sanitizeLogInRedirect(redirectCookie)
          : redirectPath;
        redirectPath = signUpRedirectCookie
          ? sanitizeSignUpRedirect(signUpRedirectCookie)
          : redirectPath;

        cookies.remove('signup_redirect');
        cookies.remove('redirect');

        const { data: partnershipAppData } = await client.query({
          query: GET_PARTNERSHIP_APP_DATA,
        });
        dispatch('setCharitySource', partnershipAppData.getPartnershipAppData);

        if (affiliateSlug) {
          const { data: affiliateData } = await client.query({
            query: GET_PARTNERSHIP_AFFILIATE_FEATURES,
            variables: {
              slug: affiliateSlug,
            },
          });

          if (affiliateData.getPartnershipAffiliateFeatures) {
            const {
              dashboardEnabled,
              willEnabled,
              powerOfAttorneyEnabled,
              endOfLifeEnabled,
              giftEnabled,
              checkoutLegalAdviceEnabled,
              affiliateUserIdEnabled,
              affiliateUserIdFieldName,
              couponCodeEnabled,
              couponCodeFieldName,
            } = affiliateData.getPartnershipAffiliateFeatures;

            dispatch('affiliate/setFeatures', {
              dashboardEnabled,
              willEnabled,
              powerOfAttorneyEnabled,
              endOfLifeEnabled,
              giftEnabled,
              checkoutLegalAdviceEnabled,
              affiliateUserIdEnabled,
              affiliateUserIdFieldName,
              couponCodeEnabled,
              couponCodeFieldName,
            });
          }
        }
      }

      if (
        window.location.pathname !== redirectPath &&
        (window.location.pathname === $auth.options.redirect.home ||
          redirectPath !== dashboardPath)
      ) {
        window.location.href = getBaseUrl() + redirectPath;
      }
    } catch (e) {
      console.error(e.message);
    }
  }
}
