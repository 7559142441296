export default () => ({
  priceLegalAdvice: null,
  priceLegalAdviceGift: null,
  pricePartnerWill: null,
  pricePartnerPoa: null,
  pricePoa: null,
  pricePoaGift: null,
  priceRecentUnlock: null,
  priceSubscription: null,
  priceSubscriptionRenewal: null,
  priceUnlock: null,
  priceWill: null,
  priceWillGift: null,
});
