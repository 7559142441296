export default (_ctx, inject) => {
  const stateCodeToStateName = (stateCode) => {
    switch (stateCode.toLowerCase()) {
      case 'act':
        return 'Australian Capital Territory';
      case 'nsw':
        return 'New South Wales';
      case 'nt':
        return 'Northern Territory';
      case 'qld':
        return 'Queensland';
      case 'sa':
        return 'South Australia';
      case 'tas':
        return 'Tasmania';
      case 'vic':
        return 'Victoria';
      case 'wa':
        return 'Western Australia';
      default:
        return 'State not set';
    }
  };
  const auStates = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
  const auStatesOptions = auStates.map((australianState) => {
    return {
      text: stateCodeToStateName(australianState),
      value: australianState,
    };
  });

  inject('auStates', auStates);
  inject('auStatesOptions', auStatesOptions);
};
