export default () => ({
  showCompletionPage: false,
  progressPercentage: 0,
  modules: [
    'About Yourself',
    'Guardians',
    'Executors',
    'Estate',
    'Gifts',
    'Assets',
    'Funeral',
  ].map((name) => ({
    name,
    completedSteps: 0,
    totalSteps: 0,
    progress: 0,
    completed: false,
  })),
});
