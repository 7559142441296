import GET_DIRECTORY_PERSON from '@/graphql/queries/GetDirectoryPerson';
import UPDATE_DIRECTORY_PERSON from '@/graphql/mutations/UpdateDirectoryPerson';

export default {
  async getUserDirectoryPerson({ commit, state }, { id, forceFetch = false }) {
    if (!forceFetch && state.user.id) return;
    const {
      data: { getDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_DIRECTORY_PERSON,
      variables: {
        id,
      },
    });

    if (!getDirectoryPerson) return;
    commit('setUserDirectoryPerson', getDirectoryPerson);
  },
  async updateUserDirectoryPerson({ commit, state }, data) {
    const {
      data: { updateDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: UPDATE_DIRECTORY_PERSON,
      variables: {
        input: {
          id: state.user.id,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          dateOfBirth: data.dateOfBirth,
          residentialAddress: {
            country: data.country,
          },
        },
      },
    });
    if (!updateDirectoryPerson) return;
    commit('setUserDirectoryPerson', updateDirectoryPerson);
  },
};
