export default {
  setPrices(state, data) {
    const prices = data.reduce((newObject, { product, finalPrice }) => {
      newObject[product] = finalPrice;
      return newObject;
    }, {});
    state.priceLegalAdvice = prices.LEGAL_ADVICE;
    state.priceLegalAdviceGift = prices.LEGAL_ADVICE_GIFT;
    state.pricePartnerWill = prices.PARTNER_WILL;
    state.pricePartnerPoa = prices.PARTNER_POA;
    state.pricePoa = prices.POA;
    state.pricePoaGift = prices.POA_GIFT;
    state.priceRecentUnlock = prices.RECENT_UNLOCK;
    state.priceSubscription = prices.SUBSCRIPTION;
    state.priceSubscriptionRenewal = prices.SUBSCRIPTION_RENEWAL;
    state.priceUnlock = prices.UNLOCK;
    state.priceWill = prices.WILL;
    state.priceWillGift = prices.WILL_GIFT;
  },
};
