import GET_PRICES_QUERY from '@/graphql/queries/getPrices';

export default {
  async getPrices({ rootState, state, commit }, forceFetch) {
    const notFetched = Object.values(state).every((value) => value === null);
    if (notFetched || forceFetch) {
      const {
        data: {
          getPrices: { pricedItems },
        },
      } = await this.app.apolloProvider.defaultClient.query({
        fetchPolicy: 'no-cache',
        query: GET_PRICES_QUERY,
        variables: {
          products: [
            'LEGAL_ADVICE',
            'LEGAL_ADVICE_GIFT',
            'PARTNER_WILL',
            'PARTNER_POA',
            'POA',
            'POA_GIFT',
            'RECENT_UNLOCK',
            'SUBSCRIPTION',
            'SUBSCRIPTION_RENEWAL',
            'UNLOCK',
            'WILL',
            'WILL_GIFT',
          ],
          discountCode: rootState.coupon.couponLatestReferral,
        },
      });
      commit('setPrices', pricedItems);
    }
  },
};
