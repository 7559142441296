export default function ({ redirect, store, app }) {
  const userDirectoryPerson =
    store.getters['directory-person/userDirectoryPerson'];
  const affiliate = store.state.affiliate;
  const affiliateSlug = affiliate.slug || 'sw';
  const { affiliateUserIdEnabled, couponCodeEnabled } = affiliate.features;
  const onboardingPageIsRequired = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      residentialAddress: { country },
    } = userDirectoryPerson;

    if (!firstName || !lastName || !dateOfBirth) {
      return true;
    }
    if (couponCodeEnabled && !store.getters['coupon/couponLatestReferral']) {
      return true;
    }
    if (affiliateUserIdEnabled && !store.getters.affiliateUserId) {
      return true;
    }
    if (app.$ff.showOnboardingCountryQuestion() && !country) {
      return true;
    }

    return false;
  };

  const isOnboardingPage = app.context.route.path.includes('/onboarding/user');

  if (onboardingPageIsRequired() && !isOnboardingPage) {
    return redirect(app.localePath(`/${affiliateSlug}/onboarding/user`));
  } else if (!onboardingPageIsRequired() && isOnboardingPage) {
    return redirect(app.localePath(`/${affiliateSlug}`));
  }
}
