export default {
  userDirectoryPerson(state) {
    return state.user;
  },
  userIsAustralian(state) {
    return (
      !state.user.residentialAddress?.country ||
      state.user.residentialAddress.country === 'AU'
    );
  },
};
