import CREATE_END_OF_LIFE_ITEM_MUTATION from '@/graphql/mutations/CreateEndOfLifeItem';
import UPDATE_END_OF_LIFE_ITEM_MUTATION from '@/graphql/mutations/UpdateEndOfLifeItem';
import { SensitiveModel } from '@/plugins/sensitive/SensitiveModel';

const endOfLifeDefaults = {
  resourceCollection: 'END_OF_LIFE_ITEMS',
  privacyTargetCollection: 'END_OF_LIFE_ITEMS',
  creationResolver: (data) => Object.values(data)[0].endOfLifeItem,
  createMutation: CREATE_END_OF_LIFE_ITEM_MUTATION,
  updateMutation: UPDATE_END_OF_LIFE_ITEM_MUTATION,
};

const endOfLifeMappingDefaults = {
  'data.name': 'name',
  'data.notes': 'notes',
};

export const EndOfLifeItem = {
  DEFAULT: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
  LICENCE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.licenceNumber': 'identification.number',
      'data.issueCountry': 'identification.issuer_primary',
      'data.issueLocation': 'identification.issuer_secondary',
      'data.expirationDate': 'identification.expiry_date',
    },
  }),
  PASSPORT: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.passportNumber': 'identification.number',
      'data.issueCountry': 'identification.issuer_primary',
      'data.expirationDate': 'identification.expiry_date',
    },
  }),
  BIRTH_CERTIFICATE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.registrationNumber': 'identification.number',
      'data.registrationDate': 'identification.issue_date',
    },
  }),
  MEDICARE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.cardNumber': 'identification.number',
    },
  }),
  TAXATION: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.tfn': 'identification.number',
    },
  }),
  RESIDENCE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.country': 'identification.issuer_primary',
      'data.identifier': 'identification.number',
    },
  }),
  VISA: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.country': 'identification.issuer_primary',
      'data.identifier': 'identification.number',
    },
  }),
  GOVERNMENT_CONCESSION: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.identifier': 'identification.number',
    },
  }),
  RELATIONSHIP: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.partnerLegalName': 'identification.name',
      'data.identifier': 'identification.number',
    },
  }),
  WILL: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.locationOfDocument': 'physical_item.location',
    },
  }),
  POWER_OF_ATTORNEY: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.locationOfDocument': 'physical_item.location',
    },
  }),
  BANK_ACCOUNT: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
  PROPERTY: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.addressLine1': 'address.street_address',
      'data.addressLine2': 'address.extended_address',
      'data.addressSuburb': 'address.locality',
      'data.addressState': 'address.region',
      'data.addressPostcode': 'address.postcode',
      'data.addressCountry': 'address.country',
    },
  }),
  BUSINESS: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.businessName': 'organisation.legal_name',
    },
  }),
  TRUST: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.trustName': 'organisation.legal_name',
      'data.identifier': 'organisation.business_number',
    },
  }),
  FINANCIAL_ASSETS: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.identifier': 'account.account_number',
    },
  }),
  SUPERANNUATION: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.memberNumber': 'account.account_number',
    },
  }),
  INSURANCE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.policyNumber': 'account.account_number',
    },
  }),
  VEHICLE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.numberPlate': 'identification.number',
    },
  }),
  CREDIT_CARD: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.accountName': 'account.account_name',
    },
  }),
  LOAN: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.accountName': 'account.account_name',
    },
  }),
  UTILITY: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.amount': 'payment.amount',
    },
  }),
  SUBSCRIPTION: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.amount': 'payment.amount',
    },
  }),
  DONATION: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.amount': 'payment.amount',
    },
  }),
  GOVERNMENT_BENEFIT: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.amount': 'payment.amount',
    },
  }),
  EMPLOYMENT: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.employer': 'organisation.legal_name',
    },
  }),
  ORGAN_DONATION: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
  MEDICAL_DEVICE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
  FUNERAL_PREFERENCE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
  FUNERAL_PREPAID_POLICY: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
  EMOTIONAL_MESSAGE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.message': 'instruction.description',
    },
  }),
  EMOTIONAL_STORY: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {
      ...endOfLifeMappingDefaults,
      'data.story': 'instruction.description',
    },
  }),
  CONTACT: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: {},
  }),
  GUARDIANSHIP: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
  FINANCIAL_VALUABLE: new SensitiveModel({
    ...endOfLifeDefaults,
    jsonMapping: endOfLifeMappingDefaults,
  }),
};
