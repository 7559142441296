import cookies from 'js-cookie';

export const state = () => ({
  slug: 'sw',
  branding: {
    logo: null,
    theme: null,
    darker: null,
    lighter: null,
    landingImage: null,
  },
  features: {
    dashboardEnabled: true,
    willEnabled: true,
    powerOfAttorneyEnabled: true,
    endOfLifeEnabled: true,
    giftEnabled: true,
    checkoutLegalAdviceEnabled: true,
    affiliateUserIdEnabled: false,
    couponCodeEnabled: false,
  },
});

export const getters = {
  slug: (state) => state.slug,
  branding: (state) => state.branding,
  features: (state) => state.features,
};

export const mutations = {
  setSlug(state, value) {
    state.slug = value;
  },
  setBranding(state, value) {
    state.branding = value;
  },
  setFeatures(state, value) {
    state.features = value;
  },
};

export const actions = {
  setSlug({ commit }, value) {
    commit('setSlug', value);
  },
  setBranding({ commit }, value) {
    commit('setBranding', value);
  },
  setFeatures({ commit }, value) {
    commit('setFeatures', value);
  },
  resetAffiliate({ commit }) {
    commit('setSlug', 'sw');
    commit('setBranding', {
      logo: null,
      theme: '#00D9BF',
      darker: '#00A894',
      lighter: '#D2F9E2',
      landingImage: null,
    });
    commit('setFeatures', {
      dashboardEnabled: true,
      willEnabled: true,
      powerOfAttorneyEnabled: true,
      endOfLifeEnabled: true,
      giftEnabled: true,
      checkoutLegalAdviceEnabled: true,
    });
    cookies.remove('affiliate_slug');
  },
};
