export const state = () => ({
  submissionsPageIndex: 0,
  submissionsStatusFilter: null,
  submissionsSearch: '',
  usersPageIndex: 0,
  usersPageSearch: '',
  couponSearch: '',
  couponsPageIndex: 0,
  userRoleFilter: null,
  cremationRequestStatusFilter: null,
  institutionsPageIndex: 0,
  institutionsSearchQuery: '',
  partnershipsPageIndex: 0,
  partnershipsSearchQuery: '',
  endOfLifeCategoriesPageIndex: 0,
});

export const getters = {
  submissionsPageIndex: (state) => state.submissionsPageIndex,
  submissionsStatusFilter: (state) => state.submissionsStatusFilter,
  submissionsSearch: (state) => state.submissionsSearch,

  couponsPageIndex: (state) => state.couponsPageIndex,
  couponSearch: (state) => state.couponSearch,

  usersPageIndex: (state) => state.usersPageIndex,
  usersPageSearch: (state) => state.usersPageSearch,
  userRoleFilter: (state) => state.userRoleFilter,

  cremationRequestStatusFilter: (state) => state.cremationRequestStatusFilter,

  institutionsPageIndex: (state) => state.institutionsPageIndex,
  institutionsSearchQuery: (state) => state.institutionsSearchQuery,

  partnershipsPageIndex: (state) => state.partnershipsPageIndex,
  partnershipsSearchQuery: (state) => state.partnershipsSearchQuery,

  endOfLifeCategoriesPageIndex: (state) => state.endOfLifeCategoriesPageIndex,
};

export const mutations = {
  decrementSubmissionsPageIndex(state) {
    state.submissionsPageIndex--;
  },
  incrementSubmissionsPageIndex(state) {
    state.submissionsPageIndex++;
  },
  submissionsPageIndex(state, value) {
    state.submissionsPageIndex = value;
  },
  submissionsStatusFilter(state, value) {
    state.submissionsStatusFilter = value;
  },
  submissionsSearch(state, value) {
    state.submissionsSearch = value;
  },
  decrementCouponsPageIndex(state) {
    state.couponsPageIndex--;
  },
  incrementCouponsPageIndex(state) {
    state.couponsPageIndex++;
  },
  couponsPageIndex(state, value) {
    state.couponsPageIndex = value;
  },
  incrementUsersPageIndex(state) {
    state.usersPageIndex++;
  },
  decrementUsersPageIndex(state) {
    state.usersPageIndex--;
  },
  setUsersPageIndex(state, value) {
    state.usersPageIndex = value;
  },
  setUsersPageSearch(state, value) {
    state.usersPageSearch = value;
  },
  setCouponSearch(state, value) {
    state.couponSearch = value;
  },
  userRoleFilter(state, value) {
    state.userRoleFilter = value;
  },
  cremationRequestStatusFilter(state, value) {
    state.cremationRequestStatusFilter = value;
  },
  setInstitutionsSearchQuery(state, value) {
    state.institutionsSearchQuery = value;
  },
  setInstitutionsPageIndex(state, value) {
    state.institutionsPageIndex = value;
  },
  setPartnershipsPageIndex(state, value) {
    state.partnershipsPageIndex = value;
  },
  setPartnershipsSearchQuery(state, value) {
    state.partnershipsSearchQuery = value;
  },
  setEndOfLifeCategoriesPageIndex(state, value) {
    state.endOfLifeCategoriesPageIndex = value;
  },
};

export const actions = {
  nextSubmissionsPage({ commit }) {
    commit('incrementSubmissionsPageIndex');
  },
  prevSubmissionsPage({ commit }) {
    commit('decrementSubmissionsPageIndex');
  },
  resetSubmissionsPageIndex({ commit }) {
    commit('submissionsPageIndex', 0);
  },
  setSubmissionsStatusFilter({ commit }, value) {
    commit('submissionsStatusFilter', value);
  },
  setSubmissionsSearch({ commit }, value) {
    commit('submissionsSearch', value);
  },
  nextCouponsPage({ commit }) {
    commit('incrementCouponsPageIndex');
  },
  prevCouponsPage({ commit }) {
    commit('decrementCouponsPageIndex');
  },
  resetCouponsPageIndex({ commit }) {
    commit('couponsPageIndex', 0);
  },
  nextUsersPage({ commit }) {
    commit('incrementUsersPageIndex');
  },
  prevUsersPage({ commit }) {
    commit('decrementUsersPageIndex');
  },
  resetUsersPageIndex({ commit }) {
    commit('setUsersPageIndex', 0);
  },
  setUsersPageSearch({ commit }, value) {
    commit('setUsersPageSearch', value);
  },
  setCouponSearch({ commit }, value) {
    commit('setCouponSearch', value);
  },
  setUserRoleFilter({ commit }, value) {
    commit('userRoleFilter', value);
  },
  setCremationRequestStatusFilter({ commit }, value) {
    commit('cremationRequestStatusFilter', value);
  },
  setInstitutionsSearchQuery({ commit }, value) {
    commit('setInstitutionsSearchQuery', value);
  },
  nextInstitutionsPage({ commit, state }) {
    commit('setInstitutionsPageIndex', state.institutionsPageIndex + 1);
  },
  prevInstitutionsPage({ commit, state }) {
    commit('setInstitutionsPageIndex', state.institutionsPageIndex - 1);
  },
  resetInstitutionsPageIndex({ commit }) {
    commit('setInstitutionsPageIndex', 0);
  },
  setPartnershipsSearchQuery({ commit }, value) {
    commit('setPartnershipsSearchQuery', value);
  },
  nextPartnershipsPage({ commit, state }) {
    commit('setPartnershipsPageIndex', state.partnershipsPageIndex + 1);
  },
  prevPartnershipsPage({ commit, state }) {
    commit('setPartnershipsPageIndex', state.partnershipsPageIndex - 1);
  },
  resetPartnershipsPageIndex({ commit }) {
    commit('setPartnershipsPageIndex', 0);
  },
  nextEndOfLifeCategoriesPage({ commit, state }) {
    commit(
      'setEndOfLifeCategoriesPageIndex',
      state.endOfLifeCategoriesPageIndex + 1
    );
  },
  prevEndOfLifeCategoriesPage({ commit, state }) {
    commit(
      'setEndOfLifeCategoriesPageIndex',
      state.endOfLifeCategoriesPageIndex - 1
    );
  },
};
