export default {
  priceLegalAdvice: (state) => state.priceLegalAdvice,
  priceLegalAdviceGift: (state) => state.priceLegalAdviceGift,
  pricePartnerWill: (state) => state.pricePartnerWill,
  pricePartnerPoa: (state) => state.pricePartnerPoa,
  pricePoa: (state) => state.pricePoa,
  pricePoaGift: (state) => state.pricePoaGift,
  priceRecentUnlock: (state) => state.priceRecentUnlock,
  priceSubscription: (state) => state.priceSubscription,
  priceSubscriptionRenewal: (state) => state.priceSubscriptionRenewal,
  priceUnlock: (state) => state.priceUnlock,
  priceWill: (state) => state.priceWill,
  priceWillGift: (state) => state.priceWillGift,
};
