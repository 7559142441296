export default {
  showCompletionPage: (state) => state.showCompletionPage,
  progressPercentage: (state) => state.progressPercentage,
  allModulesCompleted: (state) =>
    state.modules.every(({ completed }) => completed),
  modulesProgress: (state) =>
    Object.fromEntries(
      state.modules.map(({ name, progress }) => [name, progress])
    ),
};
