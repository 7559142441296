export default {
  setUserDirectoryPerson(state, data) {
    for (const key of Object.keys(data)) {
      if (data[key] === null) continue;
      if (typeof data[key] === 'object') {
        for (const subKey of Object.keys(data[key])) {
          if (data[key][subKey] === null) continue;
          state.user[key][subKey] = data[key][subKey];
        }
        continue;
      }
      state.user[key] = data[key];
    }
  },
};
