import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';

export class SensitiveModel {
  static $apollo;
  static $vault;

  resourceCollection;
  privacyTargetCollection;
  createMutation;
  updateMutation;
  jsonMapping;
  metaMapping;

  static initialise($apollo, $vault) {
    SensitiveModel.$apollo = $apollo;
    SensitiveModel.$vault = $vault;
  }

  constructor({
    resourceCollection,
    creationResolver = (data) => Object.values(data)[0],
    privacyTargetCollection = resourceCollection,
    createMutation,
    updateMutation,
    jsonMapping,
    metaMapping = [],
  }) {
    this.resourceCollection = resourceCollection;
    this.creationResolver = creationResolver;
    this.privacyTargetCollection = privacyTargetCollection;
    this.createMutation = createMutation;
    this.updateMutation = updateMutation;
    this.jsonMapping = jsonMapping;
    this.metaMapping = metaMapping;
  }

  _mapMetaToObject(data) {
    const mappedData = merge({}, data);
    this.metaMapping.forEach((key) => {
      set(
        mappedData,
        key,
        Object.fromEntries(get(data, key).map(({ key, value }) => [key, value]))
      );
    });
    return mappedData;
  }

  _mapObjectToMeta(metaData) {
    const mappedData = merge({}, metaData);
    this.metaMapping.forEach((metaKey) => {
      set(
        mappedData,
        metaKey,
        Object.entries(get(metaData, metaKey)).map(([key, value]) => ({
          key,
          value,
        }))
      );
    });
    return mappedData;
  }

  // Remove sensitive data from creation object
  // Needs much more for nested objects, other Mappers, arrays etc
  _strip(data) {
    const mappedData = this._mapMetaToObject(data);
    const safe = merge({}, mappedData);
    const sensitive = {};

    Object.keys(this.jsonMapping).forEach((sensitiveKey) => {
      set(sensitive, sensitiveKey, get(mappedData, sensitiveKey));
      unset(safe, sensitiveKey);
    });

    return {
      safe: this._mapObjectToMeta(safe),
      sensitive,
    };
  }

  // Add tokenised values back into data
  _merge(data, tokens) {
    return mergeWith(merge({}, data), tokens, (objValue, srcValue) => {
      if (typeof objValue === 'string' && srcValue === undefined) {
        return '';
      }
    });
  }

  _mapToPrivacyFields(sensitive) {
    return Object.entries(this.jsonMapping).reduce(
      (privacyVaultFields, [apiField, privacyField]) => {
        set(privacyVaultFields, privacyField, get(sensitive, apiField));
        return privacyVaultFields;
      },
      {}
    );
  }

  _mapToApiFields(tokens) {
    return this._mapObjectToMeta(
      Object.entries(this.jsonMapping).reduce(
        (apiFields, [apiField, privacyField]) => {
          set(apiFields, apiField, get(tokens, privacyField));
          return apiFields;
        },
        {}
      )
    );
  }

  async create(ownerId, data) {
    const { safe } = this._strip(data);
    const response = await SensitiveModel.$apollo.mutate({
      mutation: this.createMutation,
      variables: safe,
    });
    const { id: resourceId } = this.creationResolver(response.data);
    return await this.update(ownerId, resourceId, data);
  }

  async update(ownerId, resourceId, data) {
    const { sensitive } = this._strip(data);
    let tokens = {};
    if (Object.keys(sensitive).length > 0) {
      let targetId;
      ({
        privacyVaultTarget: { targetId },
        tokens,
      } = await SensitiveModel.$vault.save({
        privacyVaultTargetCollection: this.privacyTargetCollection,
        ownerId,
        resourceTargetId: resourceId,
        record: this._mapToPrivacyFields(sensitive),
      }));

      await SensitiveModel.$vault.setPrivacyVaultTarget({
        resourceTarget: {
          resourceId,
          resourceCollection: this.resourceCollection,
        },
        privacyVaultTarget: {
          targetId,
          targetCollection: this.privacyTargetCollection,
        },
      });
    }
    const tokenisedData = this._merge(data, this._mapToApiFields(tokens));
    const response = await SensitiveModel.$apollo.mutate({
      mutation: this.updateMutation,
      variables: {
        id: resourceId,
        ...tokenisedData,
      },
    });

    return this.creationResolver(response.data);
  }

  async reveal(resourceId, data) {
    const tokens = await SensitiveModel.$vault.revealByResourceTarget({
      resourceTarget: {
        resourceId,
        resourceCollection: this.resourceCollection,
      },
    });

    return tokens ? this._merge(data, this._mapToApiFields(tokens)) : data;
  }
}
